//
import GListWebPageService from '@/services/api/General/GListWebPage'
import RulesService from '@/services/rules'
import { VueEditor } from 'vue2-editor'
import axios from 'axios'
//
export default {
  name: 'v-view-account',
  components: { 
    VueEditor, 
  },
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      filters: {
        limit: 10,
        page: 1,
      },
      pWebPageCreate: {
        code: 'contest',
        active: false,
      },
      pWebPageDelete: {},
      pWebPageStored: {},
      pWebPageUpdate: {},
      pWebPages: {
        data: [],
        last_page: 1,
      },
    }
  },
  methods: {

    /**
     * 
     */
    handleCreateReset() {
      this.pWebPageCreate = GListWebPageService.toCreate()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      let payload = new FormData();
      this.pWebPageCreate.active ? this.pWebPageCreate.active = 1: this.pWebPageCreate.active = 0
      payload.append('active',JSON.stringify(this.pWebPageCreate.active))
      payload.append('code',this.pWebPageCreate.code)
      payload.append('content',this.pWebPageCreate.content)
      payload.append('image',this.pWebPageCreate.image,this.pWebPageCreate.image.name)
      payload.append('name',this.pWebPageCreate.name)
      //console.log('el payload queda>',payload) 
      //await GListWebPageService.create(this.pWebPageCreate).then(this.handleCreateSuccess).catch(this.handleError)
      const route =
        process.env.VUE_APP_API_ROUTE +
        '/web_page/new_web_page'
      axios  
        .post(route, payload)
        .then(this.handleCreateSuccess)
        .catch(this.handleError)
        //gImageGallery.transfer(gImageGallery.form([ 'image','hipervinculo' ])) revisar esto despues, puede servir
      this.busy.create = false
    },

    /**
     * 
     */
    handleCreateSuccess(payload) {
      this.handleReset()
      this.handleCreateReset()
      GListWebPageService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(pWebPage) {
      this.dialogs.delete   = true
      this.pWebPageDelete  = pWebPage
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await GListWebPageService.delete(this.pWebPageDelete).then(this.handleDeleteSuccess).catch(this.handleError)
      this.busy.delete = false
    },

    /**
     * 
     */
    handleDeleteSuccess() {
      this.handleReset()
      this.dialogs.delete = false
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.pWebPages = await GListWebPageService.getBy(this.filters)
      this.busy.listed = false
    },

    /**
     * 
     */
    handleUpdateClick(pWebPage) {
      this.dialogs.update   = true
      this.pWebPageUpdate  = { ...pWebPage }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.pWebPageUpdate  = { ...this.pWebPageStored }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      //se crea el formdata para mandar la imagen y el resto de los atributos
      //ya que la imagen tiene que ir en el form para poder tomarla bien
      let payloadUpload = new FormData();
      this.pWebPageUpdate.active ? this.pWebPageUpdate.active = 1: this.pWebPageUpdate.active = 0
      payloadUpload.append('active',JSON.stringify(this.pWebPageUpdate.active))
      payloadUpload.append('code',this.pWebPageUpdate.code)
      payloadUpload.append('content',this.pWebPageUpdate.content)
      if(this.pWebPageUpdate.image) payloadUpload.append('image',this.pWebPageUpdate.image,this.pWebPageUpdate.image.name)
      payloadUpload.append('name',this.pWebPageUpdate.name)
      //se pasa el id del concurso a actualizar
      const ruta = '/web_page/'+this.pWebPageUpdate.id
      const route =
        process.env.VUE_APP_API_ROUTE + ruta
      axios  
        .post(route, payloadUpload)
        .then(this.handleCreateSuccess)
        .catch(this.handleError)
      this.busy.update = false
      this.dialogs.update = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      GListWebPageService.translate({ response: payload }).then(this.$toast.success)
      this.handleReset()
    },

    /**
     * 
     */
    handleError(payload) {
      GListWebPageService.translate(payload).then(this.$toast.error)
    },
  },
  created() {
    this.handleReset()
  },
  props: {

    /**
     * 
     */
    fileRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },
    /**
     * reglas para la imagen
     */
    imageRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.page'() {
      this.handleReset()
    }
  },
}